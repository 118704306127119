<template>
  <div class="error-cpmtent-layout">
    <div class="error-content">
      <div class="error-img-operations">
        <img src="../../assets/image/404.svg" />
      </div>
      <div class="error-text-operations">
        <div class="error-text-operations_1">403</div>
        <div class="error-text-operations_2">抱歉，你没有访问权限</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage403',
  components: {},
  mounted() {
    this.startCountDown();
  },
  data() {
    return {};
  },
  methods: {
    startCountDown() {
      const durationTime = 3;
      let count = durationTime;
      const key = 'updateMessage';
      const countDown = () => {
        if (count > 0) {
          this.$message.info({ content: `${count}秒后跳转可访问界面`, key, duration: durationTime });
          count -= 1;
          setTimeout(countDown, 1000);
        } else {
          this.$router.push({ name: 'Main' });
        }
      };
      countDown();
    },
  },
};
</script>

<style lang="less" scoped>
.error-cpmtent-layout {
  height: 100%;
  display: flex;
  .error-content {
    margin: auto;
    display: flex;
    height: 650px;
    justify-content: center;
    align-items: center;
    .error-img-operations {
      width: auto;
    }
    .error-text-operations {
      .error-text-operations_1 {
        margin-top: 30%;
        font-size: 8em;
        color: #505050;
      }
      .error-text-operations_2 {
        font-size: x-large;
        color: #707070;
      }
    }
  }
}
</style>
